import React from "react"
import EpisodePlayer from "../../components/EpisodePlayer"
import { Column, Container, Row } from "../../components/Grid"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import Quicklinks from "../../components/Quicklinks"
import Resource from "../../components/Resource"
import Section from "../../components/Section"
import SEO from "../../components/Seo"

const EducatorsSpanishPage = ({ location }) => {
  // let hashName = ""

  // useEffect(() => {
  //   const timer = setTimeout(function() {
  //     window.location.hash = ""
  //     window.location.hash = hashName
  //   }, 1000)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [hashName])

  // const isBrowser = typeof window !== "undefined"

  // if (isBrowser) {
  //   hashName = window.location.hash.replace("#", "")
  // }

  const queryParameters = new URLSearchParams(location.search)
  const keyIndex = queryParameters.get("keyIndex")
  let [key, index] = keyIndex ? keyIndex.split("_") : ""
  index = Number.parseInt(index)

  return (
    <Layout>
      <SEO title="Educadores" />
      {/***************  HERO ***************/}
      <Section className="hero">
        <Hero
          title="Educadores"
          infographic="img-hero-infographic-educators.png"
          body="Inicie la conversación sobre el uso indebido de sustancias, uso seguro de los medicamentos y salud mental utilizando recursos para el aula alineados con los estándares curriculares."
          image="img-hero-educators.jpg"
        />
      </Section>

      <Section className="midgray-background">
        <Container>
          <Row>
            <Quicklinks
              title="Enlaces rápidos:"
              className="educators__quicklinks"
              links={[
                {
                  target: "#K-5",
                  label: "K–5",
                },
                {
                  target: "#6-8",
                  label: "6–8",
                },
                {
                  target: "#9-12",
                  label: "9–12",
                },
                {
                  target: "#StudentsModule",
                  label: "Módulo para el estudiante",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/*************** Grades K-5 DLBs *****************/}
      <Section className="midgray-background" id="K-5">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grado K–5"
              presetVideo={key === "GK-5"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "El uso seguro de los medicamentos (K-2)",
                  videoDuration: "5:14 minutos",
                  personDescription:
                    "Conoceremos a Dani, una farmacéutica de CVS que nos explicará qué son los medicamentos, cómo tomarlos de forma segura y qué hacer ante una emergencia.",
                  gradeRange: "Grados K–2",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes conocerán el poder de los medicamentos y la importancia de utilizarlos de forma segura. Aprenderán sobre los medicamentos recetados, los de venta libre y las vitaminas, y sabrán cómo actuar en caso de emergencia.",
                  actions: [
                    {
                      type: "download",
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MedicationSafety-Educator-V3.0_ES.pptx",
                      size: "PPT 702.7MB",
                      className: "episode-player__action-text",
                      logName: "Medication-Safety-K-2-Educator-ES.pptx",
                    },
                    {
                      type: "download",
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-MedicationSafety-K-2-Educator_ES.pdf",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                      logName: "Medication-Safety-K-2-Educator-ES.pdf",
                    },
                  ],
                  guidSrc: "2416cbec-11ad-46f0-b885-708e7a1d1470",
                  posterSrc: "CVS_DLB1_TN.jpg",
                  listImage: "medication safety K-2.jpg",
                  listDescription:
                    "Los estudiantes conocerán el poder de los medicamentos y la importancia de utilizarlos de forma segura. ",
                },
                {
                  title: "El uso seguro de los medicamentos (3.º a 5.º)",
                  videoDuration: "4:28 minutos",
                  personDescription:
                    "Conoceremos a Kahra, una farmacéutica de CVS que ayudará a los estudiantes de primaria a entender las diferencias entre los medicamentos con receta y los de venta libre.",
                  gradeRange: "Grados 3.º a 5.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán sobre el poder de los medicamentos y la importancia de su uso seguro. Asimismo, compararán y contrastarán los medicamentos recetados con los de venta libre, y comprenderán qué hacer en caso de emergencia.",

                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Medication-Safety-Educator-V2.1_ES.pptx",
                      logName: "MedicationSafety-Educator-ES.pptx",
                      type: "download",
                      size: "PPT 100MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-Medication-Safety-3-5-Educator-ES.pdf",
                      logName: "MedicationSafety-Educator-Guide-ES.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "fd53c516-14b9-4d4a-8459-c1c23a5d8c23",
                  posterSrc: "video-kahra-960x540.jpg",
                  listImage: "medication safety 3-5.jpg",
                  listDescription:
                    "Los estudiantes aprenderán sobre el poder de los medicamentos y la importancia de su uso seguro.",
                },
                {
                  title: "Control del estrés (3.º a 5.º)",
                  videoDuration: "5:50 minutos",
                  personDescription:
                    "Conoceremos a Jennifer, una farmacéutica de CVS que ayudará a los estudiantes a tener una conversación sobre salud mental, a entender qué es el estrés y, además, ofrecerá estrategias saludables y prácticas diarias para controlar el estrés en sus vidas.",
                  gradeRange: "Grados 3.º a 5.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Una vez que hayan aprendido los aspectos esenciales de la salud, los estudiantes se centrarán en la salud mental, analizando las causas y efectos del estrés. Podrán descubrir consejos y estrategias para favorecer su propia salud mental, y serán capaces de aplicarlos en las situaciones estresantes que puedan encontrar durante su vida.",
                  actions: [
                    {
                      type: "download",
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-ManagingStress-Educator-V3.0_ES.pptx",
                      size: "PPT 752MB",
                      className: "episode-player__action-text",
                      logName: "Managing-Stress-Educator-ES.pptx",
                    },
                    {
                      type: "download",
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-ManagingStress-Educator_ES.pdf",
                      size: "PDF 1.5MB",
                      className: "episode-player__action-text",
                      logName: "Managing-Stress-Educator.pdf",
                    },
                  ],
                  guidSrc: "c04dbc65-ce34-4b4a-b68e-d7492eb2dd72",
                  posterSrc: "CVS_DLB2_TN.jpg",
                  listImage: "managing stress.jpg",
                  listDescription:
                    "Una vez que hayan aprendido los aspectos esenciales de la salud, los estudiantes se centrarán en la salud mental, analizando las causas y efectos del estrés.",
                },
                {
                  title: "Todo sobre los medicamentos (3.º a 5.º)",
                  videoDuration: "5:58 minutos",
                  personDescription:
                    "Conoceremos a Ro, un farmacéutico de CVS que hablará sobre el poder de los medicamentos, analizando cómo se descubrieron los medicamentos con receta, cómo viajan por nuestro cuerpo y por qué los medicamentos vienen en distintas presentaciones.",
                  gradeRange: "Grados 3.º a 5.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes estudiarán cómo se crean los medicamentos, cómo se administran, cómo viajan por el cuerpo y el porqué de sus múltiples presentaciones.",
                  actions: [
                    {
                      type: "download",
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-AllAboutMedication-Educator-V1.0_ES.pptx",
                      size: "PPT 787.4MB",
                      className: "episode-player__action-text",
                      logName: "All-About-Medication-Educator-ES.pptx",
                    },
                    {
                      type: "download",
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-AllAboutMedication-Educator_ES.pdf",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                      logName: "All-About-Medication-Educator-ES.pdf",
                    },
                  ],
                  guidSrc: "99fda6b5-a752-463b-a330-8c103ff0b323",
                  posterSrc: "CVS_DLB3_TN.jpg",
                  listImage: "all about medication.jpg",
                  listDescription:
                    "Los estudiantes estudiarán cómo se crean los medicamentos, cómo se administran, cómo viajan por el cuerpo y el porqué de sus múltiples presentaciones.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/*************** Grades 6-8 DLBs *****************/}
      <Section className="midgray-background" id="6-8">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grados 6.º a 8.º"
              presetVideo={key === "G6-8"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Salud mental (6.º a 12.º)",
                  videoDuration: "4:47 minutos",
                  personDescription:
                    "Conoceremos a James, un farmacéutico de CVS que hablará a los estudiantes sobre las benzodiacepinas, una clase de medicamentos que se pueden utilizar para tratar los casos más severos de ansiedad, y el peligro que pueden suponer si se utilizan mal o se combinan con otras sustancias.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo. Los estudiantes aprenderán sobre el papel que los medicamentos recetados pueden desempeñar para el tratamiento de la ansiedad y la depresión y analizarán los riesgos asociados con el uso indebido de medicamentos recetados u otras sustancias.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MentalHealth-Educator-V1.1_ES.pptx",
                      logName: "MentalHealth-Educator-ES.pptx",
                      type: "download",
                      size: "PPT 159MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path: "/docs/CVS-Pharma-DLB-MentalHealth-Educator-ES.pdf",
                      logName: "MentalHealth-Educator-Guide-ES.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                  posterSrc: "video-james-960x540.jpg",
                  listImage: "mental health.jpg",
                  listDescription:
                    "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo.",
                },
                {
                  title: "La presión de los compañeros (6.º a 12.º)",
                  videoDuration: "4:24 minutos",
                  personDescription:
                    "Conoceremos a Lance, un farmacéutico de CVS que hablará con los estudiantes sobre la presión de los pares, las estrategias de rechazo y cómo lograr un impacto positivo en sus compañeros.",
                  gradeRange: "Grados 6.º a 12.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "En este Módulo de lecciones digitales, los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Peer-Pressure-Educator-ES.pptx",
                      logName: "Responding to Peer Pressure.pptx-Educators-ES",
                      type: "download",
                      size: "PPT 17.9MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path: "/docs/CVS-DoK-Peer-Pressure-Educator-ES.pdf",
                      logName: "Responding to Peer Pressure.pdf-Educators-ES",
                      type: "download",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                  posterSrc: "img-toolkit-video-peer-pressure.jpg",
                  listImage: "peer preassure.jpg",
                  listDescription:
                    "Los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                },
                {
                  title: "Estimulantes recetados (6.º a 12.º)",
                  videoDuration: "4:05 minutos",
                  personDescription:
                    "Conoceremos a Tyler, un farmacéutico de CVS que les hablará a los estudiantes sobre los estimulantes con receta, cómo funcionan y por qué su uso indebido puede ser peligroso.",
                  gradeRange: "Grados (6.º a 12.º)",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH. Los estudiantes analizarán los peligros asociados con el uso indebido de estimulantes recetados, los efectos que el uso indebido puede tener en el cerebro y en el cuerpo, e identificarán a dónde pueden acudir en caso de necesitar ayuda.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Prescription-Stimulants-Educator-V1.1_ES.pptx",
                      logName: "PrescriptionStimulants-Educator.pptx",
                      type: "download",
                      size: "PPT 61MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-Prescription-Stimulants-Educator-V1.1-ES.pdf",
                      logName: "PrescriptionStimulants-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                  posterSrc: "video-tyler-960x540.jpg",
                  listImage: "prescription stimulants.jpg",
                  listDescription:
                    "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH.",
                },
                {
                  title: "Uso y abuso de los opioides (6.º a 12.º)",
                  videoDuration: "5:08 minutos",
                  personDescription:
                    "Conoceremos a Vicki, una farmacéutica de CVS que conversará con los estudiantes sobre cómo brindar apoyo a quienes tienen problemas con el abuso de sustancias o padecen un trastorno por consumo de sustancias.",
                  gradeRange: "Grados 6.º a 8.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán sobre los opioides, los efectos de estas drogas en el cerebro humano y los efectos similares a las endorfinas en el cuerpo humano. Los estudiantes harán una comparación sobre las diferencias entre los medicamentos recetados y las drogas ilícitas.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Opioid-Use-And-Misuse-Educator-ES.pptx",
                      logName: "Opioid Use and Misuse.pptx - Educators ES",
                      type: "download",
                      size: "PPT 9MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-DoK-Opioid-Use-And-Misuse-Educator-ES.pdf",
                      logName: "Opioid Use and Misuse.pdf - Educators ES",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "2526ede5-f86a-4e83-bf5f-6f318b69c9f9",
                  posterSrc: "img-toolkit-video-help-around.jpg",
                  listImage: "opioid use and misuse.jpg",
                  listDescription:
                    "Los estudiantes aprenderán sobre los opioides, los efectos de estas drogas en el cerebro humano y los efectos similares a las endorfinas en el cuerpo humano.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/*************** Grades 9-12 DLBs *****************/}
      <Section className="midgray-background" id="9-12">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grados 9.º a 12.º"
              presetVideo={key === "G9-12"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Salud mental (6.º a 12.º)",
                  videoDuration: "4:47 minutos",
                  personDescription:
                    "Conoceremos a James, un farmacéutico de CVS que hablará a los estudiantes sobre las benzodiacepinas, una clase de medicamentos que se pueden utilizar para tratar los casos más severos de ansiedad, y el peligro que pueden suponer si se utilizan mal o se combinan con otras sustancias. ",
                  gradeRange: "Grados 6.º a 12.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo. Los estudiantes aprenderán sobre el papel que los medicamentos recetados pueden desempeñar para el tratamiento de la ansiedad y la depresión y analizarán los riesgos asociados con el uso indebido de medicamentos recetados u otras sustancias.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MentalHealth-Educator-V1.1_ES.pptx",
                      logName: "MentalHealth-Educator-ES.pptx",
                      type: "download",
                      size: "PPT 159MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path: "/docs/CVS-Pharma-DLB-MentalHealth-Educator-ES.pdf",
                      logName: "MentalHealth-Educator Guide ES.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                  posterSrc: "video-james-960x540.jpg",
                  listImage: "mental health.jpg",
                  listDescription:
                    "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo.",
                },
                {
                  title: "La presión de los compañeros (6.º a 12.º)",
                  videoDuration: "4:24 minutos",
                  personDescription:
                    "Conoceremos a Lance, un farmacéutico de CVS que hablará con los estudiantes sobre la presión de los pares, las estrategias de rechazo y cómo lograr un impacto positivo en sus compañeros.",
                  gradeRange: "Grados 6.º a 12.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "En este Módulo de lecciones digitales, los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Peer-Pressure-Educator-ES.pptx",
                      logName: "Responding to Peer Pressure.pptx-Educators-ES",
                      type: "download",
                      size: "PPT 17.9MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path: "/docs/CVS-DoK-Peer-Pressure-Educator-ES.pdf",
                      logName: "Responding to Peer Pressure.pdf-Educators-ES",
                      type: "download",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                  posterSrc: "img-toolkit-video-peer-pressure.jpg",
                  listImage: "peer preassure.jpg",
                  listDescription:
                    "Los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                },
                {
                  title: "Estimulantes recetados  (6.º a 12.º)",
                  videoDuration: "4:05 minutos",
                  personDescription:
                    "Conoceremos a Tyler, un farmacéutico de CVS que les hablará a los estudiantes sobre los estimulantes con receta, cómo funcionan y por qué su uso indebido puede ser peligroso.",
                  gradeRange: "Grados 6.º a 12.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH. Los estudiantes analizarán los peligros asociados con el uso indebido de estimulantes recetados, los efectos que el uso indebido puede tener en el cerebro y en el cuerpo, e identificarán a dónde pueden acudir en caso de necesitar ayuda.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Prescription-Stimulants-Educator-V1.1_ES.pptx",
                      logName: "PrescriptionStimulants-Educator-ES.pptx",
                      type: "download",
                      size: "PPT 61MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path:
                        "/docs/CVS-Pharma-DLB-Prescription-Stimulants-Educator-V1.1-ES.pdf",
                      logName: "PrescriptionStimulants-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                  posterSrc: "video-tyler-960x540.jpg",
                  listImage: "prescription stimulants.jpg",
                  listDescription:
                    "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH.",
                },
                {
                  title: "Los opioides y el cerebro (9.º a 12.º)",
                  videoDuration: "3:53 minutos",
                  personDescription:
                    "Conoceremos a Fernando, un farmacéutico de CVS que hablará con los estudiantes sobre los opioides, el riesgo asociado al consumo de sustancias y lo que deben hacer si se les receta un opioide.",
                  gradeRange: "Grados 9.º a 12.º",
                  lessonDuration: "45-60 minutos",
                  lessonDescription:
                    "Los estudiantes recibirán un resumen general de lo que son los opioides y examinarán sus efectos a corto y largo plazo en el cerebro y el cuerpo. Aprenderán sobre el abuso de sustancias y el trastorno por consumo de sustancias.",
                  actions: [
                    {
                      label: "Lección Digital",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Opioids-And-Brain-Educator-ES.pptx",
                      logName: "Opioid Use and the Brain.pptx - Educators ES",
                      type: "download",
                      size: "PPT 12.9MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Guía para el educador",
                      path: "/docs/CVS-DoK-Opioids-And-Brain-Educator-ES.pdf",
                      logName: "Opioid Use and the Brain.pdf - Educators ES",
                      type: "download",
                      size: "PDF 1.9MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "4d8892c2-8bb3-4e13-a6d4-3272f313bcae",
                  posterSrc: "img-toolkit-video-opioids-effects.jpg",
                  listImage: "opiods and brain.jpg",
                  listDescription:
                    "Los estudiantes recibirán un resumen general de lo que son los opioides y examinarán sus efectos a corto y largo plazo en el cerebro y el cuerpo.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/***************  RESOURCE HORIZONTAL ***************/}
      <Section
        className="resource-container midgray-background"
        id="StudentsModule"
      >
        <Column span={12}>
          <Resource
            label="EXPLORACIÓN DIGITAL "
            image="thumbnail-digital-exploration@2x.jpg"
            title="El uso indebido de medicamentos recetados y el cuerpo y cerebro de los adolescentes "
            grade="Grados 6.º a 12.º"
            description={
              <p>
                En este módulo interactivo, los estudiantes aprenderán sobre las
                consecuencias del uso indebido de medicamentos recetados en el
                cerebro y cuerpo en pleno desarrollo de los adolescentes. Esta
                información les permitirá tomar decisiones inteligentes al
                momento de enfrentar la presión de los compañeros respecto al
                uso indebido de medicamentos recetados.
              </p>
            }
            actions={[
              {
                label: "Comencemos",
                path: "/prescription-medication-misuse",
                type: "module",
                locked: false,
              },
              {
                label: "Guía para el educador",
                path: "/docs/CVS-PHARMA-Dose-of-Knowledge-DigExModuleGuide.pdf",
                type: "download",
                size: "PDF 3.6 MB",
                locked: false,
              },
            ]}
            horizontal
          />
        </Column>
      </Section>

      {/***************  ABOVE FOOTER CALLOUT ***************/}
      {/* <Section className="subfooter">
        <Container>
          <Row>
            <Column span={4}>
              <img
                src={SubFooterImage}
                className="subfooter-image"
                alt="Title: Facts, Info etc."
              />
            </Column>
            <Column span={8}>
              <h3>Dato:</h3>
              <p>
                Casi el 30 % de los adolescentes revelaron que han presenciado y
                visto personalmente el uso ilegal de drogas en su vida real. El
                lugar más común en el que estos adolescentes observaron el
                consumo de drogas fue en la escuela, lo que resulta preocupante.
              </p>

              <p>
                -{" "}
                <em>
                  La visión de los adolescentes sobre las drogas, el alcohol y
                  la nicotina: Una encuesta nacional sobre las actitudes de los
                  adolescentes hacia las sustancias adictivas. Asociación para
                  ponerle fin a las adicciones{" "}
                </em>
              </p>
            </Column>
          </Row>
        </Container>
      </Section> */}
    </Layout>
  )
}

export default EducatorsSpanishPage
