import React from "react"
import EpisodePlayer from "../../components/EpisodePlayer"
import { Column, Container, Row } from "../../components/Grid"
import Hero from "../../components/Hero"
import Image from "../../components/Image"
import Layout from "../../components/Layout"
import Quicklinks from "../../components/Quicklinks"
import Resource from "../../components/Resource"
import Section from "../../components/Section"
import SEO from "../../components/Seo"

const EducatorsPage = ({ location }) => {
  // let hashName = ""

  // useEffect(() => {
  //   const timer = setTimeout(function() {
  //     window.location.hash = ""
  //     window.location.hash = hashName
  //   }, 1000)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [hashName])

  // const isBrowser = typeof window !== "undefined"

  // if (isBrowser) {
  //   hashName = window.location.hash.replace("#", "")
  // }

  const queryParameters = new URLSearchParams(location.search)
  const keyIndex = queryParameters.get("keyIndex")
  let [key, index] = keyIndex ? keyIndex.split("_") : ""
  index = Number.parseInt(index)

  return (
    <Layout>
      <SEO title="Educators" />
      {/***************  HERO ***************/}
      <Section className="hero">
        <Hero
          title="Educators"
          infographic="img-hero-infographic-educators.png"
          body="Explore interactive resources designed to empower students to make smart, healthy choices when confronted with peer pressure and the risk of substance misuse."
          image="img-hero-educators.jpg"
        />
      </Section>

      {/************** QUICKLINKS ***********/}
      <Section className="midgray-background">
        <Container>
          <h4>Quick Links</h4>
          <Row className="ml-0">
            <Quicklinks
              title="Quick Links:"
              className="educators__quicklinks"
              links={[
                {
                  target: "#CYOA",
                  label: "Interactive",
                },
                {
                  target: "#K-5",
                  label: "K–5",
                },
                {
                  target: "#6-8",
                  label: "6–8",
                },
                {
                  target: "#9-12",
                  label: "9–12",
                },
                {
                  target: "#StudentsModule",
                  label: "Student Module",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/******* INTERACTIVE ANIMATION ********/}
      <Section className="midgray-background">
        <Container>
          <Row>
            <Column span={8}>
              <h2>Interactive Animation</h2>
              <p>
                Engage in an interactive animated adventure empowering healthy
                choices and decision-making skills.
              </p>
            </Column>
          </Row>
          <div
            className="resource__label"
            id="CYOA"
            style={{ position: "relative" }}
          >
            Interactive Animation
            <Image
              filename="CVS-Animation-New-Flag.png"
              className="new-flag-educator"
            />
          </div>
          <Row className="educators__CYA-row white-background">
            <Column span={8} className="pt-1">
              <Resource
                image="CVS-DoK-Educator-Think-It-Through-Animation-L-Thumb@3x.png"
                title="Think It Through: A choose-your-own-path experience"
                grade="Grades 9-12"
                resourceContainerClass="educators__CYA-resource"
                description={
                  <p>
                    Facing the common consequences of poor time management,
                    students will practice healthy decision-making skills to
                    navigate a hectic day while faced with various social
                    pressures and quick fixes leading up to the big test!
                  </p>
                }
                actions={[
                  {
                    label: "Begin Adventure",
                    path: "/educators/interactive",
                    type: "module",
                    moduleName: "Choose Your Own Adventure",
                    locked: false,
                  },
                ]}
              />
            </Column>
            <Column span={4} className="pt-1">
              <Resource
                image="CVS-DoK-Educator-Think-It-Through-Edu-Guide-Thumb@3x.png"
                title="Educator Guide"
                grade="Grades 9-12"
                resourceContainerClass="educators__CYA-resource"
                description={
                  <p>
                    Build background knowledge, guide discussions, and engage in
                    activities to empower students in the practice of making
                    healthy choices when facing peer pressure related to
                    substance misuse.
                  </p>
                }
                actions={[
                  {
                    label: "Educator Guide",
                    path: "/docs/CVS-Pharma-ATS-EdGuide-FINAL.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
              />
            </Column>
          </Row>
          {/* Ready to use activities */}
          <Row className="white-background educators__CYA-row pt-1 pb-1 educators__equal-height">
            <Column span={4}>
              <Resource
                image="CVS-Pharma-RTU-Thumbnail-ImpactsofSocialMedia.jpg"
                title="Impacts of Social Media"
                grade="Grades 9-12"
                resourceContentClass="educators__CYA-resource-content"
                resourceContainerClass="educators__CYA-resource-with-shadow"
                description={
                  <p>
                    Learn about being mindful and constructive when spending
                    time online with this wellness activity.
                  </p>
                }
                actions={[
                  {
                    label: "Open Ready-to-Use Activity",
                    url:
                      "https://app.discoveryeducation.com/learn/player/6abe7d1a-47fe-4523-b131-93e2e8f44cae",
                    type: "external",
                    locked: false,
                  },
                ]}
              />
            </Column>
            <Column span={4}>
              <Resource
                image="CVS-Pharma-RTU-Thumbnail-NavigatingSocialInfluence.jpg"
                title="Social Influence"
                grade="Grades 9-12"
                resourceContentClass="educators__CYA-resource-content"
                resourceContainerClass="educators__CYA-resource-with-shadow"
                description={
                  <p>
                    Learn about navigating peer pressure through positive
                    interactions with this wellness activity.
                  </p>
                }
                actions={[
                  {
                    label: "Open Ready-to-Use Activity",
                    url:
                      "https://app.discoveryeducation.com/learn/player/8fab0b86-90cf-49de-9de0-942ab94741e3",
                    type: "external",
                    locked: false,
                  },
                ]}
              />
            </Column>
            <Column span={4}>
              <Resource
                image="CVS-Pharma-RTU-Thumbnail-MentalBreathingSpace.jpg"
                title="Stress Management"
                grade="Grades 9-12"
                resourceContentClass="educators__CYA-resource-content"
                resourceContainerClass="educators__CYA-resource-with-shadow"
                description={
                  <p>
                    Learn about proactive stress management techniques with this
                    wellness activity.
                  </p>
                }
                actions={[
                  {
                    label: "Open Ready-to-Use Activity",
                    url:
                      "https://app.discoveryeducation.com/learn/player/9a51e47c-ccf7-4315-8c78-7ee96608ffa1",
                    type: "external",
                    locked: false,
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/*************** Grades K-5 DLBs *****************/}
      <Section className="midgray-background" id="K-5">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grades K–5"
              presetVideo={key === "GK-5"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Medication Safety (K–2)",
                  videoDuration: "5:14 min",
                  personDescription:
                    "Meet Dani, a CVS Pharmacist who will explain what medications are, how to make sure medicines are taken safely and what to do in case of an emergency.",
                  gradeRange: "Grades K–2",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will be introduced to the power of medicine and the importance of medication safety. They will learn about prescription medicine, over-the-counter medicine, and vitamins, and they will understand what to do in case of an emergency.",
                  actions: [
                    {
                      type: "download",
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MedicationSafety-Educator-V3.0.pptx",
                      size: "PPT 703MB",
                      className: "episode-player__action-text",
                      logName: "Medication-Safety-K-2-Educator.pptx",
                    },
                    {
                      type: "download",
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-MedicationSafety-Educator-V2.0.pdf",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                      logName: "Medication-Safety-K-2-Educator.pdf",
                    },
                    {
                      label: "Ready-to-Use Activity",
                      moduleName: "Medication Safety Module",
                      type: "module",
                      path:
                        "https://app.discoveryeducation.com/learn/player/db6a94c5-e842-4f67-bc93-b165c3b835c0",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "2416cbec-11ad-46f0-b885-708e7a1d1470",
                  posterSrc: "CVS_DLB1_TN.jpg",
                  listImage: "medication safety K-2.jpg",
                  listDescription:
                    "Students will be introduced to the power of medicine and the importance of medication safety.",
                },
                {
                  title: "Medication Safety (3–5)",
                  videoDuration: "4:28 min",
                  personDescription:
                    "Meet Kahra, a CVS Pharmacist who will help elementary students understand the differences between prescription and over-the-counter medications.",
                  gradeRange: "Grades 3–5",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn about the power of medicine and the importance of medication safety. They will compare and contrast prescription medicines and over-the-counter medicines, and they will understand what to do in the case of an emergency.",

                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MedicationSafety-Educator.pptx",
                      logName: "MedicationSafety-Educator.pptx",
                      type: "download",
                      size: "PPT 103MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MedicationSafety-Educator_Guide.pdf",
                      logName: "MedicationSafety-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Ready-to-Use Activity",
                      moduleName: "Medication Safety Module",
                      type: "module",
                      path:
                        "https://app.discoveryeducation.com/learn/player/eb126ac8-fc4e-4ae3-8879-84ce897eb9ce",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "fd53c516-14b9-4d4a-8459-c1c23a5d8c23",
                  posterSrc: "video-kahra-960x540.jpg",
                  listImage: "medication safety 3-5.jpg",
                  listDescription:
                    "Students will learn about the power of medicine and the importance of medication safety.",
                },
                {
                  title: "Managing Stress (3–5)",
                  videoDuration: "5:50 min",
                  personDescription:
                    "Meet Jennifer, a CVS Pharmacist who will help students begin a conversation about mental health, understand what stress is, and provide healthy and practical daily strategies for managing stress in their lives.",
                  gradeRange: "Grades 3–5",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "After students learn about the key components of health, they will focus on mental health as they consider the causes and effects of stress. They will explore tips and strategies for supporting their own mental health, and they will apply these strategies to stressful scenarios they may experience throughout their lives.",
                  actions: [
                    {
                      type: "download",
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-ManagingStress-Educator-V3.0.pptx",
                      size: "PPT 752MB",
                      className: "episode-player__action-text",
                      logName: "Managing-Stress-Educator.pptx",
                    },
                    {
                      type: "download",
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-ManagingStress-Educator-V2.0.pdf",
                      size: "PDF 1.4MB",
                      className: "episode-player__action-text",
                      logName: "Managing-Stress-Educator.pdf",
                    },
                    {
                      label: "Ready-to-Use Activity",
                      path:
                        "https://app.discoveryeducation.com/learn/player/aa9cf163-a6dd-42f8-8fb9-761362e2f1c4",
                      type: "module",
                      moduleName: "Managing Stress Module",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "c04dbc65-ce34-4b4a-b68e-d7492eb2dd72",
                  posterSrc: "CVS_DLB2_TN.jpg",
                  listImage: "managing stress.jpg",
                  listDescription:
                    "After students learn about the key components of health, they will focus on mental health as they consider the causes and effects of stress.",
                },
                {
                  title: "All About Medication (3–5)",
                  videoDuration: "5:58 min",
                  personDescription:
                    "Meet Ro, a CVS Pharmacist who will talk about the power of medicine, exploring how prescription medication was discovered, how it travels through our bodies and why medicine comes in different forms.",
                  gradeRange: "Grades 3–5",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will explore how medicine is created, how it can be administered, how it travels through the body and the reasons behind its many forms.",
                  actions: [
                    {
                      type: "download",
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-AllAboutMedication-Educator-V1.0.pptx",
                      size: "PPT 794MB",
                      className: "episode-player__action-text",
                      logName: "All-About-Medication-Educator.pptx",
                    },
                    {
                      type: "download",
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-AllAboutMedication-Educator-V0.2.pdf",
                      size: "PDF 1.3MB",
                      className: "episode-player__action-text",
                      logName: "All-About-Medication-Educator.pdf",
                    },
                  ],
                  guidSrc: "99fda6b5-a752-463b-a330-8c103ff0b323",
                  posterSrc: "CVS_DLB3_TN.jpg",
                  listImage: "all about medication.jpg",
                  listDescription:
                    "Students will explore how medicine is created, how it can be administered, how it travels through the body and the reasons behind its many forms.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/*************** Grades 6-8 DLBs *****************/}
      <Section className="midgray-background" id="6-8">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grades 6–8"
              presetVideo={key === "G6-8"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Mental Health (6–12)",
                  videoDuration: "4:47 min",
                  personDescription:
                    "Meet James, a CVS pharmacist who will talk to students about benzodiazepines, a class of drugs that may be used to treat the most severe types of anxiety, and how they can be dangerous if misused or combined with other substances.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body. Students will learn about the role prescription medications can play in the treatment of anxiety and depression and review the risks associated with misusing prescription medications or other substances.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Educator.pptx",
                      logName: "MentalHealth-Educator.pptx",
                      type: "download",
                      size: "PPT 163MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Educator_Guide.pdf",
                      logName: "MentalHealth-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                    {
                      type: "module",
                      moduleName: "Mental Health Module",
                      label: "Ready-to-Use Activity",
                      path:
                        "https://app.discoveryeducation.com/learn/player/ad14fa9a-ddfd-4d99-9568-0d6604032b0d",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                  posterSrc: "video-james-960x540.jpg",
                  listImage: "mental health.jpg",
                  listDescription:
                    "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body.",
                },
                {
                  title: "Peer Pressure (6–12)",
                  videoDuration: "4:24 min",
                  personDescription:
                    "Meet Lance, a CVS pharmacist who will talk to students about peer pressure, refusal tactics, and how they can positively impact their peers.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "In this Digital Lesson Bundle, students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-PEER-PRESSURE-V11.pptx",
                      logName: "Responding to Peer Pressure.pptx- Educators",
                      type: "download",
                      size: "PPT 22.4MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-Responding-to-Peer-Pressure-Educators.pdf",
                      logName: "Responding to Peer Pressure.pdf- Educators",
                      type: "download",
                      size: "PDF 1.3MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Ready-to-Use Activity",
                      moduleName: "Peer Pressure Module",
                      type: "module",
                      path:
                        "https://app.discoveryeducation.com/learn/player/0e5aaf23-81ef-407c-9e03-e0156818772d",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                  posterSrc: "img-toolkit-video-peer-pressure.jpg",
                  listImage: "peer preassure.jpg",
                  listDescription:
                    "Students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                },
                {
                  title: "Prescription Stimulants (6–12)",
                  videoDuration: "4:05 min",
                  personDescription:
                    "Meet Tyler, a CVS pharmacist who will talk to students about prescription stimulants, how they work, and how it can be dangerous to misuse them.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD. Students will explore the dangers associated with prescription stimulant misuse, the effects misuse can have on the brain and body, and they will identify where they can turn for help if needed.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Educator.pptx",
                      logName: "PrescriptionStimulants-Educator.pptx",
                      type: "download",
                      size: "PPT 63MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Educator_Guide.pdf",
                      logName: "PrescriptionStimulants-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                  posterSrc: "video-tyler-960x540.jpg",
                  listImage: "prescription stimulants.jpg",
                  listDescription:
                    "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD.",
                },
                {
                  title: "Opioid Use and Misuse (6–8)",
                  videoDuration: "5:08 min",
                  personDescription:
                    "Meet Vicki, a CVS pharmacist who will talk to students about ways to support those who may be struggling with substance misuse or substance use disorder.",
                  gradeRange: "Grades 6–8",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn about opioids, the effects of these drugs on the human brain, and the endorphin-like effects on the human body. Students will compare the differences between prescription medications and illicit drugs.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-OPIOID-USE-AND-MISUSE-V11-fonts_embedded.pptx",
                      logName: "Opioid Use and Misue.pptx - Educators",
                      type: "download",
                      size: "PPT 11.3MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-Opiod-Use-and-Misuse-Educators.pdf",
                      logName: "Opioid Use and Misue.pdf - Educators",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "2526ede5-f86a-4e83-bf5f-6f318b69c9f9",
                  posterSrc: "img-toolkit-video-help-around.jpg",
                  listImage: "opioid use and misuse.jpg",
                  listDescription:
                    "Students will learn about opioids, the effects of these drugs on the human brain, and the endorphin-like effects on the human body.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/*************** Grades 9-12 DLBs *****************/}
      <Section className="midgray-background" id="9-12">
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              playerLabelTitle="Grades 9–12"
              presetVideo={key === "G9-12"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Mental Health (6–12)",
                  videoDuration: "4:47 min",
                  personDescription:
                    "Meet James, a CVS pharmacist who will talk to students about benzodiazepines, a class of drugs that may be used to treat the most severe types of anxiety, and how they can be dangerous if misused or combined with other substances.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body. Students will learn about the role prescription medications can play in the treatment of anxiety and depression and review the risks associated with misusing prescription medications or other substances.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Educator.pptx",
                      logName: "MentalHealth-Educator.pptx",
                      type: "download",
                      size: "PPT 163MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Educator_Guide.pdf",
                      logName: "MentalHealth-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.2MB",
                      className: "episode-player__action-text",
                    },
                    {
                      type: "module",
                      moduleName: "Mental Health Module",
                      label: "Ready-to-Use Activity",
                      path:
                        "https://app.discoveryeducation.com/learn/player/ad14fa9a-ddfd-4d99-9568-0d6604032b0d",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                  posterSrc: "video-james-960x540.jpg",
                  listImage: "mental health.jpg",
                  listDescription:
                    "Students will learn that mental health is a key component of overall health, review strategies to support their mental health, and explore the effects stress, anxiety, and depression can have on the brain and body.",
                },
                {
                  title: "Peer Pressure (6–12)",
                  videoDuration: "4:24 min",
                  personDescription:
                    "Meet Lance, a CVS pharmacist who will talk to students about peer pressure, refusal tactics, and how they can positively impact their peers.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "In this Digital Lesson Bundle, students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-PEER-PRESSURE-V11.pptx",
                      logName: "Responding to Peer Pressure.pptx- Educators",
                      type: "download",
                      size: "PPT 22.4MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-Responding-to-Peer-Pressure-Educators.pdf",
                      logName: "Responding to Peer Pressure.pdf- Educators",
                      type: "download",
                      size: "PDF 1.3MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Ready-to-Use Activity",
                      moduleName: "Peer Pressure Module",
                      type: "module",
                      path:
                        "https://app.discoveryeducation.com/learn/player/0e5aaf23-81ef-407c-9e03-e0156818772d",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                  posterSrc: "img-toolkit-video-peer-pressure.jpg",
                  listImage: "peer preassure.jpg",
                  listDescription:
                    "Students will work together to develop and practice refusal skills and exit strategies to use when offered opioids or other drugs.",
                },
                {
                  title: "Prescription Stimulants (6–12)",
                  videoDuration: "4:05 min",
                  personDescription:
                    "Meet Tyler, a CVS pharmacist who will talk to students about prescription stimulants, how they work, and how it can be dangerous to misuse them.",
                  gradeRange: "Grades 6–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD. Students will explore the dangers associated with prescription stimulant misuse, the effects misuse can have on the brain and body, and they will identify where they can turn for help if needed.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Educator.pptx",
                      logName: "PrescriptionStimulants-Educator.pptx",
                      type: "download",
                      size: "PPT 63MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Educator_Guide.pdf",
                      logName: "PrescriptionStimulants-Educator Guide.pdf",
                      type: "download",
                      size: "PDF 1.1MB",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                  posterSrc: "video-tyler-960x540.jpg",
                  listImage: "prescription stimulants.jpg",
                  listDescription:
                    "Students will learn about prescription stimulants and how they may be used in the treatment of ADHD.",
                },
                {
                  title: "Opioids and the Brain (9–12)",
                  videoDuration: "3:53 min",
                  personDescription:
                    "Meet Fernando, a CVS pharmacist who will talk to students about opioids, the risk associated with substance misuse, and what students should do if they are prescribed an opioid.",
                  gradeRange: "Grades 9–12",
                  lessonDuration: "45-60 min",
                  lessonDescription:
                    "Students will receive an overview of what opioids are and examine the short-term and long-term effects they have on the brain and body. Students will learn about substance misuse and substance use disorder.",
                  actions: [
                    {
                      label: "Digital Lesson",
                      path:
                        "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-OPIOIDS-AND-BRAIN-V11-fonts_embedded.pptx",
                      logName: "Opioid Use and the Brain.pptx - Educators",
                      type: "download",
                      size: "PPT 13.7MB",
                      className: "episode-player__action-text",
                    },
                    {
                      label: "Educator Guide",
                      path:
                        "/docs/CVS-Pharma-DLB-Opiods-and-the-brain-Educators.pdf",
                      logName: "Opioid Use and the Brain.pdf - Educators",
                      type: "download",
                      size: "PDF 2.3MB",
                      className: "episode-player__action-text",
                    },
                    {
                      type: "module",
                      moduleName: "Opioids and the Brain Module",
                      label: "Ready-to-Use Activity",
                      path:
                        "https://app.discoveryeducation.com/learn/player/66f5f28c-f2e4-4cb0-b4f9-218994b74b39",
                      className: "episode-player__action-text",
                    },
                  ],
                  guidSrc: "4d8892c2-8bb3-4e13-a6d4-3272f313bcae",
                  posterSrc: "img-toolkit-video-opioids-effects.jpg",
                  listImage: "opiods and brain.jpg",
                  listDescription:
                    "Students will receive an overview of what opioids are and examine the short-term and long-term effects they have on the brain and body.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      {/***************  RESOURCE HORIZONTAL ***************/}
      <Section
        className="resource-container midgray-background"
        id="StudentsModule"
      >
        <Column span={12}>
          <Resource
            label="Digital Exploration"
            image="thumbnail-digital-exploration@2x.jpg"
            title="Prescription Medication Misuse and the Adolescent Brain and Body"
            grade="Grades 9-12"
            description={
              <p>
                In this interactive module, students will learn about the
                effects of prescription medication misuse on the developing
                adolescent brain and body and use this information to help them
                make informed and responsible decisions when confronted with
                peer pressure regarding the misuse of prescription medications.
              </p>
            }
            actions={[
              {
                label: "Get Started",
                path: "/prescription-medication-misuse",
                type: "module",
                moduleName: "Prescription Medication Misuse",
                locked: false,
              },
              {
                label: "Educator Guide",
                path: "/docs/CVS-PHARMA-Dose-of-Knowledge-DigExModuleGuide.pdf",
                type: "download",
                size: "PDF 3.6 MB",
                locked: false,
              },
            ]}
            horizontal
          />
        </Column>
      </Section>

      {/***************  ABOVE FOOTER CALLOUT ***************/}
      {/* <Section className="subfooter">
        <Container>
          <Row>
            <Column span={4}>
              <img
                src={SubFooterImage}
                className="subfooter-image"
                alt="Title: Facts, Info etc."
              />
            </Column>
            <Column span={8}>
              <h3>Fact:</h3>
              <p>
                Overall, 50% of teens have misused a drug at least one time.
                That can include taking a non-prescribed medication, taking
                prescription medications or over-the-counter drugs at doses
                beyond what’s prescribed, and similar activities.
              </p>

              <p>
                - <em>National Center for Drug Abuse (2020)</em>
              </p>
            </Column>
          </Row>
        </Container>
      </Section> */}
    </Layout>
  )
}

export default EducatorsPage
