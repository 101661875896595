import React from "react"
import "./styles.scss"

const Container = ({ children, className = "", ...props }) => (
  <div className={`container ${className}`} {...props}>
    {children}
  </div>
)

export default Container
