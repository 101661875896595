import classNames from "classnames"
import { Link } from "gatsby"
import React, { useState } from "react"
import useLocation from "../../utils/useLocation"
import Icon from "../Icon"
import "./styles.scss"

const Menu = () => {
  // const location = useLocation()
  const [educatorsMenuOpen, setEducatorsMenuOpen] = useState(false)
  const [pharmacistsMenuOpen, setPharmacistsMenuOpen] = useState(false)

  const handleEducatorsToggle = e => {
    e.preventDefault()
    setEducatorsMenuOpen(!educatorsMenuOpen)
    setPharmacistsMenuOpen(false)
  }

  const handlePharmacistsToggle = e => {
    e.preventDefault()
    setPharmacistsMenuOpen(!pharmacistsMenuOpen)
    setEducatorsMenuOpen(false)
  }

  const IsPharmacistsPage = () => {
    const pathname = useLocation().pathname
    const pharmacistRegex = new RegExp("/pharmacists/spanish")
    return pharmacistRegex.test(pathname)
  }

  const IsEducatorsPage = () => {
    const pathname = useLocation().pathname
    const educatorsRegex = new RegExp("/educators/spanish")
    return educatorsRegex.test(pathname)
  }

  return (
    <nav>
      <ul className="primary__nav">
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            to="/"
            className="primary__nav__item__link"
          >
            Home
          </Link>
        </li>
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            to="/educators/"
            className={classNames(
              "primary__nav__item__link",
              IsEducatorsPage() && "active"
            )}
            onClick={e => handleEducatorsToggle(e)}
          >
            Educators
            <Icon
              name="caratdown"
              className={classNames(
                "primary__nav__item__link__icon",
                educatorsMenuOpen && "primary__nav__item__link__icon--rotated"
              )}
              marginLeft
            />
          </Link>
          <ul
            className={classNames(
              "primary__nav__item__submenu",
              educatorsMenuOpen && "primary__nav__item__submenu--displayed"
            )}
          >
            <li className="primary__nav__item__submenu__item">
              <Link
                activeClassName="active"
                className="primary__nav__item____submenu__item__link"
                to="/educators/"
              >
                English
              </Link>
            </li>
            <li className="primary__nav__item__submenu__item">
              <Link
                activeClassName="active"
                className="primary__nav__item____submenu__item__link"
                to="/educators/spanish"
              >
                Español
              </Link>
            </li>
          </ul>
        </li>
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            to="/students/"
            className="primary__nav__item__link"
          >
            Students
          </Link>
        </li>
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            className={classNames(
              "primary__nav__item__link",
              IsPharmacistsPage() && "active"
            )}
            to="/pharmacists/"
            onClick={e => handlePharmacistsToggle(e)}
          >
            Pharmacists
            <Icon
              name="caratdown"
              className={classNames(
                "primary__nav__item__link__icon",
                pharmacistsMenuOpen && "primary__nav__item__link__icon--rotated"
              )}
              marginLeft
            />
          </Link>
          <ul
            className={classNames(
              "primary__nav__item__submenu",
              pharmacistsMenuOpen && "primary__nav__item__submenu--displayed"
            )}
          >
            <li className="primary__nav__item__submenu__item">
              <Link
                activeClassName="active"
                className="primary__nav__item____submenu__item__link"
                to="/pharmacists/"
              >
                English
              </Link>
            </li>
            <li className="primary__nav__item__submenu__item">
              <Link
                activeClassName="active"
                className="primary__nav__item____submenu__item__link"
                to="/pharmacists/spanish/"
              >
                Español
              </Link>
            </li>
          </ul>
        </li>
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            to="/caregivers/"
            className="primary__nav__item__link"
          >
            Caregivers
          </Link>
        </li>
        <li className="primary__nav__item">
          <Link
            activeClassName="active"
            to="/pharmacy-careers/"
            className="primary__nav__item__link"
          >
            Pharmacy Careers
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Menu
