import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Column, Container, Row } from "../Grid"
import Icon from "../Icon"
import Image from "../Image"
import Video from "../Video"
import Action from "./Action"
import "./styles.scss"

const Resource = ({
  children,
  image,
  halfLabel,
  label,
  labelWithNewFlag,
  subjects,
  title,
  grade,
  duration,
  description,
  actions,
  horizontal,
  bgpattern,
  locked,
  resourceContainerClass,
  resourceContentClass,
}) => {
  const renderImage = () => {
    const videos = actions
      ? actions.filter(action => action.type === "video")
      : null
    const video = videos && videos.length === 1 ? videos[0] : false
    return (
      <div className="resource__image">
        {video && !locked ? (
          <Video {...video} imgPlaceholder={image} />
        ) : (
          <Image filename={image} />
        )}
      </div>
    )
  }

  const renderContent = (
    <div className={classNames("resource__content", resourceContentClass)}>
      {subjects && (
        <div className="resource__subjects">{subjects.join(", ")}</div>
      )}
      {title && <h3 className="resource__title">{title}</h3>}
      {grade && <div className="resource__grade">{grade}</div>}
      {duration && (
        <div className="resource__duration">
          <Icon name="clock" inline />
          {duration}
        </div>
      )}
      {description && (
        <div className="resource__description">{description}</div>
      )}
      {actions && (
        <div className="resource__actions">
          {actions.map((action, i) => (
            <Action key={i} {...action} locked={locked} />
          ))}
        </div>
      )}
      {children}
    </div>
  )

  if (horizontal) {
    return (
      <Container className={classNames("asd", resourceContainerClass)}>
        <div className="resource resource--horizontal">
          {label && (
            <div
              className={classNames(
                "resource__label",
                halfLabel && "resource__label--half"
              )}
            >
              {label}
            </div>
          )}
          {labelWithNewFlag && (
            <div
              className={classNames(
                "resource__label",
                halfLabel && "resource__label--half"
              )}
            >
              {labelWithNewFlag}
              <Image
                filename="CVS-Animation-New-Flag.png"
                className="new-flag-resource"
              />
            </div>
          )}
          <Row>
            <Column span={5}>{renderImage()}</Column>
            <Column span={7}>{renderContent}</Column>
          </Row>
        </div>
      </Container>
    )
  }

  if (bgpattern) {
    return (
      <Container>
        <div className="resource resource--horizontal bg-pattern">
          <Row>
            <Column span={5}>{renderImage()}</Column>
            <Column span={7}>{renderContent}</Column>
          </Row>
        </div>
      </Container>
    )
  }

  return (
    <div
      className={classNames(
        "resource",
        "resource--vertical",
        resourceContainerClass
      )}
    >
      {renderImage()}
      {renderContent}
    </div>
  )
}

Resource.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  subjects: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  grade: PropTypes.string,
  duration: PropTypes.string,
  description: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  horizontal: PropTypes.bool,
  bgpattern: PropTypes.bool,
  locked: PropTypes.bool,
}

export default Resource

/* Example Usage */
/*
<Resource
  label="Core Program Bundle"
  image="img-core-program-bundle.jpg"
  subjects={["Health"]}
  title="Introduction to the Risks of E-Cigarettes and Vaping"
  grade="5th Grade"
  duration="Four sessions, 30-40 minutes each"
  description={
    <>
      <p>
        Text goes here...
      </p>
    </>
  }
  actions={[
    {
      label: "Educator Guide", path: "../docs/cvs-catch-placeholder.zip", type: "download", locked: !formComplete,
    },
    {
      label: "Class Slides", path: "../docs/cvs-catch-placeholder.zip", type: "download", locked: !formComplete,
    },
  ]}
/>
*/
