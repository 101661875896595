import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Column, Container, Row } from "../Grid"
import Icon from "../Icon"
import Image from "../Image"
import Action from "../Resource/Action"
import Video from "../Video"
import "./styles.scss"

const EpisodePlayer = ({
  episodes,
  playerLabelTitle,
  className,
  locked,
  presetVideo,
  presetVideoIndex,
  style = {},
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0) // zero-based index
  useEffect(() => {
    if (
      presetVideo &&
      presetVideoIndex >= 0 &&
      presetVideoIndex < episodes.length
    ) {
      setActiveIndex(presetVideoIndex)
    }
  }, [])
  const viewerRef = useRef(null)
  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          id={`${playerLabelTitle.split(" ")[1]}_${index}`}
          className={classNames(
            "episode-player__episode",
            index === activeIndex && "episode-player__episode--active"
          )}
          onClick={() => {
            setActiveIndex(index)
            if (viewerRef.current.getBoundingClientRect()["top"] < 0) {
              viewerRef.current.scrollIntoView({ behavior: "smooth" })
            }
          }}
        >
          <div>
            <Image filename={episode.listImage} className="episode__image" />
          </div>
          <div>
            <h5 className="mb-0.25">{episode.title}</h5>
            <p className="episode__description mb-0">
              {episode.listDescription}
            </p>
          </div>
        </button>
      ))}
    </>
  )

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex]
    return (
      <>
        {locked ? (
          <Image filename={episode.posterSrc} />
        ) : (
          <Video
            guidSrc={episode.guidSrc}
            poster={episode.posterSrc}
            className="video-player"
            title={episode.title}
          />
        )}
      </>
    )
  }

  const renderCurrentData = () => {
    const episode = episodes[activeIndex]
    return (
      <Row className="episode-player__current-data-row">
        <Column span={12} className="episode-player__current-data-column">
          <div className="episode-player__intro pb-1 pt-2">
            <h3 className="episode-player__title">{episode.title}</h3>
            <p className="episode-player__16size-24height mb-0.5">
              {episode.gradeRange}
            </p>
            <p className="episode-player__person-desc mb-0.5">
              <u>{episode.lessonDuration && <strong>Video</strong>}</u>
            </p>
            <p className="mb-0.5">{episode.videoDuration}</p>
            <p className="episode-player__person-desc">
              {episode.personDescription}
            </p>
            <p className="episode-player__person-desc mb-0.5">
              <u>{episode.lessonDuration && <strong>Lesson</strong>}</u>
            </p>
            <div className="mb-0.5">
              {episode.lessonDuration && <Icon name="clock" inline />}
              <p className="episode-player__lesson-duration">
                {episode.lessonDuration}
              </p>
            </div>
            <p className="episode-player__16size-24height">
              {episode.lessonDescription}
            </p>
            {episode.actions && (
              <div>
                {episode.actions.map((action, i) => (
                  <div key={episode.title + i} className="mb-1">
                    <Action
                      path={action.path}
                      label={action.label}
                      type={action.type}
                      className={action.className}
                      format={action.format}
                      size={action.size}
                      logName={action.logName}
                      locked={locked}
                      url={action.url}
                      moduleName={action.moduleName}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Column>
      </Row>
    )
  }

  return (
    <Container
      fullWidth
      className={`episode-player ${className} pb-0`}
      style={style}
      {...props}
    >
      <Row className="mb-0.5">
        <Column>
          <h2 className="episode-player__player-label-title mb-1">
            {playerLabelTitle}
          </h2>
        </Column>
      </Row>
      <Row>
        <Column span={7}>
          <div ref={viewerRef} className="episode-player__video">
            {renderCurrentVideo()}
          </div>
          <div className="episode-player__current-data pb-2">
            {renderCurrentData()}
          </div>
        </Column>
        <Column span={5} className="pl-0 pr-0">
          <div className="episode-player__episodes">{renderEpisodes()}</div>
        </Column>
      </Row>
    </Container>
  )
}

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabelTitle: PropTypes.string,
  playerLabelSubTitle: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

EpisodePlayer.defaultProps = {
  playerLabel: "",
  className: "",
  locked: false,
}

export default EpisodePlayer
