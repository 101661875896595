import React from "react"
import DiscoveryLogo from "../../images/global/img-de-logo.png"
import CVSLogo from "../../images/theme/logo-cvs-health.svg"
import { Column, Container, Row } from "../Grid"
import Share from "../Share"
import Subscribe from "../Subscribe"

import "./styles.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__logos">
          <div className="footer__logos-sponsors">
            <a
              href="https://catch.org"
              target="_blank"
              rel="noopener noreferrer"
              className="cvs-pharmacy"
            >
              <img src={CVSLogo} alt="CVS Pharamacy" />
            </a>
            <a
              href="https://www.discoveryeducation.com"
              target="_blank"
              rel="noopener noreferrer"
              className="discovery"
            >
              <img src={DiscoveryLogo} alt="Discovery Education" />
            </a>
          </div>
          <p>
            <strong>Dose of Knowledge</strong> strives to empower educators and
            pharmacists to address substance misuse and educate students to make
            good decisions for the health and well-being of themselves and their
            community.
          </p>
        </div>
        <div className="footer__links">
          <div className="signup">
            <Subscribe
              trigger={
                <button className="global__button red">
                  Sign Up for Updates
                </button>
              }
            />
          </div>
          <div className="footer__share">
            <Share />
          </div>
          <div className="footer__hastag">
            <a
              href="https://twitter.com/hashtag/doseofknowledge?f=tweets&amp;vertical=default&amp;src=hash"
              target="_blank"
              rel="noopener noreferrer"
            >
              #DoseofKnowledge
            </a>
          </div>
        </div>
      </Container>
      <div className="footer__copyright">
        <Container>
          <Row>
            <Column span={6}>
              <div className="footer__copyright-text">
                <p>&copy; 2020. All rights are reserved.</p>
              </div>
            </Column>
            <Column span={6}>
              <div className="footer__copyright-links">
                <a href="/" className="cookie_settings">
                  Cookie Settings
                </a>{" "}
                |
                <a
                  href="https://www.cvshealth.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                |
                <a
                  href="https://www.discoveryeducation.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                |
                <a
                  href="mailto:support@bevapefree.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

Footer.defaultProps = {}
Footer.propTypes = {}
export default Footer
